// @mui
import { Grid, Typography, Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '../../../redux/store';
import { getWidgetCode } from '../../../utils/getWidgetCode';
import { useTranslation } from 'react-i18next';
import textVideoWidgetImage from '../../../assets/widget_onboarding/text_video_review.png';
import videoWidgetImage from '../../../assets/widget_onboarding/video_review.png';
import microWidgetImage from '../../../assets/widget_onboarding/micro_review_count.png';
import textWidgetImage from '../../../assets/widget_onboarding/text_review.png';
import WidgetActivation from './components/WidgetActivation';
import SendInstructionCard from './components/SendInstructionCard';
import VideoInstructionCard from './components/VideoInstructionCard';
import WidgetCodeBlock from './components/WidgetCodeBlock';
import WidgetPreviewBlock, { ImagePlaceholder } from './components/WidgetPreviewBlock';
import { HEADER } from '../../../config';
import { WidgetType } from '../../../enum';
import { selectCurrentPlatformIntegrationsMap } from '../../../redux/selectors/integrations';
import TextCardCarousel from '@simplytech/simply.widgets.review.text-cards-carousel';
import FloatingVideo from '@simplytech/simply.widgets.video.floating';
import { selectCurrentPlatformWorkspaceId } from '../../../redux/selectors/workspaces';
import { Position } from '@simplytech/simply.widgets.video.floating';
import { WidgetTypeConfig } from '../../../types';
import LeadFormPreview from 'src/pages/leadEmbedOnWebsite/Preview';

export function getPreviewWidget(type: WidgetType) {
  switch (type) {
    case WidgetType.TEXT_VIDEO_CARDS:
      return textVideoWidgetImage;

    case WidgetType.VIDEO_CARDS:
      return videoWidgetImage;

    case WidgetType.MICRO_STARS:
      return microWidgetImage;

    case WidgetType.REVIEW_CARDS:
      return ''; // textWidgetImage;

    default:
      return '';
  }
}
export function getPreviewWidgetComponent(type: WidgetType) {
  switch (type) {
    case WidgetType.TEXT_VIDEO_CARDS:
      return null; // textVideoWidgetImage;

    case WidgetType.VIDEO_CARDS:
      return null; // videoWidgetImage;

    case WidgetType.MICRO_STARS:
      return null; // microWidgetImage;

    case WidgetType.REVIEW_CARDS:
      return (
        <div
          style={{
            position: 'absolute',
            width: 1060,
            transform: 'scale(0.5)',
            left: -265,
            height: '100%',
          }}
        >
          <TextCardCarousel reviewCount={100} rating={4.5} slug="simply-review" reviews={reviews} />
        </div>
      );
    case WidgetType.FLOATING_INTRO_VIDEO:
      return (
        <div
          style={{
            position: 'absolute',
            // transform: 'scale(0.5)',
            right: 76,
            bottom: 10,
          }}
        >
          <FloatingVideo videoPlaybackId="y2UyjQh6jJg4ypP65ZzxkYg659f2gGiSgPzjO8smHXU" />
        </div>
      );
    default:
      return '';
  }
}

export default function Step2({ currentWidgetId, widgetType, isSetup }: any) {
  const theme = useTheme();
  const { t } = useTranslation();
  const workspaceId = useSelector(selectCurrentPlatformWorkspaceId) as string;
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = integrationMap[currentWidgetId];
  const isActive = currentWidget?.state === 'ACTIVE';

  const code = getWidgetCode(widgetType, {
    workspaceId,
    widgetId: currentWidgetId,
  });

  const topOffset = HEADER.DASHBOARD_DESKTOP_HEIGHT + 20;
  const image = getPreviewWidget(widgetType);
  const widgetComponent = getPreviewWidgetComponent(widgetType);

  const isVideoReady = currentWidget?.videoProcessed === true;
  const videoConfig = currentWidget?.config as WidgetTypeConfig<WidgetType.FLOATING_INTRO_VIDEO>;

  return (
    <>
      <Grid item xs={12} sm={12} md={7}>
        <Box sx={{ py: 2, pr: 4 }}>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h5">{t('widgets.setup.headline')}</Typography>
          </Box>
          <WidgetCodeBlock code={code as string} />
        </Box>
        <Box sx={{ py: 2, pr: 4 }}>
          <VideoInstructionCard tutorialVideo="https://storage.googleapis.com/simply-review-b6fb2.appspot.com/tutorials/setup-video-widget-short.mov" />
        </Box>
        <Box sx={{ pb: 4, pr: 4 }}>
          <SendInstructionCard widgetId={currentWidgetId} />
        </Box>
        {widgetType !== WidgetType.LEAD_FORM && (
          <Box sx={{ py: 2, pr: 4 }}>
            <WidgetActivation
              isActive={isActive}
              url={`${currentWidget?.metadata?.hostname}${currentWidget?.metadata?.pathname}`}
            />
          </Box>
        )}
      </Grid>

      {widgetType === WidgetType.LEAD_FORM ? (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock widgetType={WidgetType.LEAD_FORM}>
                <Stack sx={{ position: 'absolute', top: 90, left: 10 }}>
                  <Stack sx={{ transform: 'scale(0.40)' }}>
                    <LeadFormPreview />
                  </Stack>
                </Stack>
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      ) : widgetType === WidgetType.FLOATING_INTRO_VIDEO ? (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                marginTop: isSetup ? -12 : 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock>
                <div
                  style={{
                    position: 'absolute',
                    right: videoConfig?.position !== Position.bottomLeft ? 76 : 'inherit',
                    bottom: 10,
                  }}
                >
                  {videoConfig && isVideoReady && (
                    <FloatingVideo
                      videoPlaybackId={videoConfig?.videoPlaybackId}
                      position={videoConfig?.position}
                      aspectRatio={videoConfig?.aspectRatio}
                      gifFrame={videoConfig?.gifFrame}
                      height={150}
                      thumbFrame={videoConfig?.thumbFrame}
                    />
                  )}
                </div>
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={5} sx={{ position: 'relative' }}>
          <Stack sx={{ pt: 4 }} alignItems="center">
            <Box
              sx={{
                position: 'fixed',
                top: 0,
                right: 0,
                // textAlign: 'center',
                // backgroundColor: theme.palette.grey[900],
                paddingTop: `${topOffset}px`,
                px: 8,
                height: '100vh',
              }}
            >
              <WidgetPreviewBlock>
                {image && <ImagePlaceholder widgetImage={image} offsetImage={topOffset} />}
                {widgetComponent && widgetComponent}
              </WidgetPreviewBlock>
            </Box>
          </Stack>
        </Grid>
      )}
    </>
  );
}

const reviews = [
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
  {
    createTime: {
      _seconds: 1616500000,
    },
    title: 'Amazing service',
    description:
      'Quia illo tenetur quod molestiae distinctio. Quia sed quia similique quasi. Reiciendis omnis veniam voluptatem magnam sint quae voluptatum eum. Ducimus nulla adipisci aut quo omnis quia qui.',
    rating: 4.5,
    reviewer: {
      name: 'Rosie Lesch',
    },
  },
];
