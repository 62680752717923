import { Suspense, lazy, ElementType, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import {
  PATH_AFTER_LOGIN_SIMPLY_FLOW,
  PATH_AFTER_LOGIN_SIMPLY_LEADS,
  PATH_AFTER_LOGIN_SIMPLY_REVIEW,
} from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { Segment } from '../utils/segment';
import InviteList from '../pages/invites/InviteList';
import ReviewPageGuard from '../guards/ReviewPageGuard';
import CustomToken from '../pages/auth/CustomToken';
import Widget from '../pages/Integrations/Widget';
import InviteAdd from '../pages/invites/InviteAdd';
import WidgetFlow from '../pages/Integrations/flow/Widget';
import SimplyFlowGuard from '../guards/SimplyFlowGuard';
import { useSelector } from 'react-redux';
import { selectPlatform } from '../redux/slices/userSlice';
import { Platforms } from '../redux/slices/platform';
import SimplyReviewGuard from '../guards/SimplyReviewGuard';
import Social from '../pages/Social';
import VideoCustomisation from '../pages/videoCustomisation';
import SimplyLeadsGuard from '../guards/SimplyLeadsGuard';
import Leads from '../pages/leads';
import LeadPage from 'src/pages/leadPage';
import LeadSubmissions from 'src/pages/leadSubmission';
import ReviewAutomations from '../pages/automations/reviews';
import ReviewAutomationsCotainer from '../pages/automations/reviews/Container';
import LeadCustomisePage from '../pages/leadsCustomisePage';
import LinksCustomiseContainer from 'src/pages/linksCustomisePage';
import SimplyLinksGuard from '../guards/SimplyLinksGuard';
import LinksPageContainer, { LinksPage } from '../pages/linksPage';
import SimplyContentGuard from 'src/guards/SimplyContentGuard';
import Invoices from 'src/pages/invoices';
import LeadEmbed from 'src/pages/leadEmbedOnWebsite';
import SimplyBookingGuard from 'src/guards/SimplyBookingGuard';
import BookingPageGuard from 'src/guards/BookingPageGuard';
import SimplyChatGuard from 'src/guards/SimplyChatGuard';
import BookingList from 'src/pages/booking/list';
import Container from 'src/pages/Social/Container';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    Segment.page();

    return () => {};
  }, [pathname]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

function getDashboardPlatformRoute(platform: Platforms) {
  if (platform === Platforms.SIMPLY_FLOW) {
    return PATH_AFTER_LOGIN_SIMPLY_FLOW;
  }
  if (platform === Platforms.SIMPLY_REVIEW) {
    return PATH_AFTER_LOGIN_SIMPLY_REVIEW;
  }
  if (platform === Platforms.SIMPLY_LEADS) {
    return PATH_AFTER_LOGIN_SIMPLY_LEADS;
  }
  return PATH_AFTER_LOGIN_SIMPLY_REVIEW;
}

export default function Router() {
  const platform = useSelector(selectPlatform);
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        // { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'company',
      children: [
        {
          path: ':id',
          element: (
            // <GuestGuard>
            <UserProfile />
            // </GuestGuard>
          ),
        },
      ],
    },
    {
      path: 'internal/auth',
      element: (
        <AuthGuard>
          <CustomToken />
        </AuthGuard>
      ),
    },
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { path: 'industry', element: <IndustryOnboarding /> },
        // { path: 'link', element: <LinkOnboarding /> },
        // { path: 'widget', element: <WidgetOnboarding /> },
      ],
    },
    {
      path: 'dashboard/invites/add',
      element: (
        <AuthGuard>
          <SimplyReviewGuard>
            <InviteAdd />
          </SimplyReviewGuard>
        </AuthGuard>
      ),
    },
    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={getDashboardPlatformRoute(platform)} replace />,
          index: true,
        },
        {
          path: 'app',
          element: (
            <SimplyReviewGuard>
              <GeneralApp />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'user',
          children: [
            // { element: <Navigate to="/dashboard/user/profile" replace />,  },

            // { path: 'cards', element: <UserCards />, index: true },
            // { path: 'list', element: <InviteList /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invites',
          element: (
            <SimplyReviewGuard>
              <InviteList />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'reviews',
          element: (
            <SimplyReviewGuard>
              <Reviews />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'integrations',
          element: (
            <SimplyReviewGuard>
              <Integrations />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'social',
          element: (
            <SimplyReviewGuard>
              <Container />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'video-customisation',
          element: (
            <SimplyReviewGuard>
              <VideoCustomisation />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'automations',
          element: (
            <SimplyReviewGuard>
              <ReviewAutomationsCotainer />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'integrations/new',
          element: (
            <SimplyReviewGuard>
              <IntegrationsNew />
            </SimplyReviewGuard>
          ),
        },
        {
          path: 'integrations/:widgetId',
          element: (
            <SimplyReviewGuard>
              <Widget />
            </SimplyReviewGuard>
          ),
        },
        // {
        //   path: 'image-editor',
        //   element: (
        //     <SimplyReviewGuard>
        //       <ImageEditor />
        //     </SimplyReviewGuard>
        //   ),
        // },

        // SIMPLY FLOW
        // { path: 'video-flow', element: <ImageEditor /> },
        {
          path: 'flow',
          children: [
            {
              path: 'app',
              element: (
                <SimplyFlowGuard>
                  <GeneralApp />
                </SimplyFlowGuard>
              ),
            },
            {
              path: 'integrations-flow',
              element: (
                <SimplyFlowGuard>
                  <IntegrationsFlow />
                </SimplyFlowGuard>
              ),
            },
            // { path: 'integrations-flow/new', element: <IntegrationsFlowNew /> },
            {
              path: 'integrations-flow/:widgetId',
              element: (
                <SimplyFlowGuard>
                  <WidgetFlow />
                </SimplyFlowGuard>
              ),
            },
          ],
        },
        // SIMPLY LEADS
        {
          path: 'leads',
          children: [
            {
              path: 'app',
              element: (
                <SimplyLeadsGuard>
                  <GeneralApp />
                </SimplyLeadsGuard>
              ),
            },
            {
              path: 'form',
              element: (
                <SimplyLeadsGuard>
                  <Leads />
                  {/* <IntegrationsFlow /> */}
                </SimplyLeadsGuard>
              ),
            },
            {
              path: 'customise-page',
              element: (
                <SimplyLeadsGuard>
                  <LeadCustomisePage />
                  {/* <IntegrationsFlow /> */}
                </SimplyLeadsGuard>
              ),
            },
            {
              path: 'embed-on-website',
              element: (
                <SimplyLeadsGuard>
                  <LeadEmbed />
                  {/* <IntegrationsFlow /> */}
                </SimplyLeadsGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <SimplyLeadsGuard>
                  <LeadSubmissions />
                  {/* <IntegrationsFlow /> */}
                </SimplyLeadsGuard>
              ),
            },
            // { path: 'integrations-flow/new', element: <IntegrationsFlowNew /> },
          ],
        },
        {
          path: 'links',
          children: [
            {
              path: 'customise-page',
              element: (
                <SimplyLinksGuard>
                  <LinksCustomiseContainer />
                </SimplyLinksGuard>
              ),
            },
          ],
        },
        {
          path: 'content',
          children: [
            {
              path: 'app',
              element: (
                <SimplyContentGuard>
                  <ContentApp />
                </SimplyContentGuard>
              ),
            },
          ],
        },
        {
          path: 'booking',
          children: [
            {
              path: 'app',
              element: (
                <SimplyBookingGuard>
                  <GeneralApp />
                </SimplyBookingGuard>
              ),
            },
            {
              path: 'schedules',
              element: (
                <SimplyBookingGuard>
                  <BookingSchedules />
                </SimplyBookingGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <SimplyBookingGuard>
                  <BookingList />
                  {/* <IntegrationsFlow /> */}
                </SimplyBookingGuard>
              ),
            },
          ],
        },
        {
          path: 'chat',
          children: [
            {
              path: 'conversations',
              element: (
                <SimplyChatGuard>
                  <Chat />
                </SimplyChatGuard>
              ),
            },
          ],
        },
        // { path: 'image-module', element: <ImageModule /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> },
        //   ],
        // },

        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new-post', element: <BlogNewPost /> },
        //   ],
        // },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> },
        //   ],
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> },
      ],
    },
    { path: 'review/:id', element: <Review /> },
    { path: 'review/:id/:inviteId', element: <Review /> },
    {
      path: 'l/:slug',
      element: (
        <SimplyLeadsGuard>
          <LeadPage />
        </SimplyLeadsGuard>
      ),
    },
    {
      path: 'i/:slug',
      element: (
        <SimplyLinksGuard>
          <LinksPageContainer />
        </SimplyLinksGuard>
      ),
    },
    {
      path: 'r/:slug',
      element: (
        <ReviewPageGuard>
          <SimplyReviewGuard>
            <Review />
          </SimplyReviewGuard>
        </ReviewPageGuard>
      ),
    },
    {
      path: 'b/:slug/:scheduleId',
      element: (
        <BookingPageGuard>
          <SimplyBookingGuard>
            <BookingPage />
          </SimplyBookingGuard>
        </BookingPageGuard>
      ),
    },
    {
      path: 'r/:slug/:inviteId',
      element: (
        <SimplyReviewGuard>
          <Review />
        </SimplyReviewGuard>
      ),
    },
    {
      path: 'invoices',
      element: (
        <SimplyReviewGuard>
          <Invoices />
        </SimplyReviewGuard>
      ),
    },
    {
      path: 'external/widgets/:userId/:widgetId',
      element: (
        <SimplyReviewGuard>
          <WidgetInstructions />
        </SimplyReviewGuard>
      ),
    },
    { path: 'privacy', element: <Privacy /> },
    {
      path: 'c/:slug',
      element: (
        <SimplyReviewGuard>
          <UserProfile />
        </SimplyReviewGuard>
      ),
    },

    {
      path: ':slug',
      element: (
        <SimplyLinksGuard>
          <LinksPageContainer />
        </SimplyLinksGuard>
      ),
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/dashboard/app" replace />, // <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const Review = Loadable(lazy(() => import('../pages/client/Review')));
const WidgetInstructions = Loadable(lazy(() => import('../pages/client/WidgetIntructions')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const ContentApp = Loadable(lazy(() => import('../pages/content/Dashboard')));
const BookingApp = Loadable(lazy(() => import('../pages/booking/AvailbilityCalendar')));
const BookingPage = Loadable(lazy(() => import('../pages/booking/initial/BookingSchedule')));
const Reviews = Loadable(lazy(() => import('../pages/reviews/Reviews')));
const Integrations = Loadable(lazy(() => import('../pages/Integrations')));
const IntegrationsFlow = Loadable(lazy(() => import('../pages/Integrations/flow')));
// const ImageEditor = Loadable(lazy(() => import('../pages/imageEditor')));
const IntegrationsNew = Loadable(lazy(() => import('../pages/Integrations/New')));
const BookingSchedules = Loadable(lazy(() => import('../pages/booking/Schedules')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const ImageModule = Loadable(lazy(() => import('../pages/dashboard/ImageModule')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
// );
// const EcommerceProductList = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductList'))
// );
// const EcommerceProductCreate = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
// );
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/company/CompanyPage')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const InviteList = Loadable(lazy(() => import('../pages/dashboard/InviteList')));
const UserAccount = Loadable(lazy(() => import('../pages/account/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Privacy = Loadable(lazy(() => import('../pages/Privacy')));
