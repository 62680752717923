import React from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import website from '../../../../assets/widget_onboarding/placeholder_web.png';
import websiteLead from '../../../../assets/widget_onboarding/placeholder_web_lead.png';
import { WidgetType } from 'src/enum';

interface Props {
  widgetImage: string;
  offsetImage?: number;
}

export function ImagePlaceholder({
  widgetImage,
  offsetImage,
}: Pick<Props, 'widgetImage' | 'offsetImage'>) {
  return (
    <img
      src={widgetImage}
      style={{
        paddingTop: offsetImage,
        position: 'absolute',
        width: '100%',
        margin: 'auto',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
      alt="widget-placeholder"
    />
  );
}

function getPreviewTemplate(widgetType?: WidgetType) {
  if (widgetType === WidgetType.LEAD_FORM) {
    return websiteLead;
  }
  return website;
}

export default function WidgetPreviewBlock({
  widgetType,
  children,
}: {
  widgetType?: WidgetType;
  children: React.ReactNode;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const previewImg = getPreviewTemplate(widgetType);
  return (
    <>
      <Typography variant="subtitle1" color={theme.palette.common.white}>
        {t('widgets.setup.preview.headline')}
      </Typography>
      <Box sx={{ height: '100%', pt: 2 }}>
        {children}
        <img src={previewImg} alt="website-placeholder" />
      </Box>
    </>
  );
}
