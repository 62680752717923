// @mui
import {
  Grid,
  Button,
  Typography,
  Step,
  StepLabel,
  StepConnector,
  Box,
  Stepper,
} from '@mui/material';
// hooks

import { styled, useTheme } from '@mui/material/styles';
// sections

import Iconify from '../../components/Iconify';
import { useState } from 'react';
import WidgetCard from '../../components/WidgetCard';
import Step2 from './Steps/Step2';
import { Collection, WidgetType } from '../../enum';
import { getCollection } from '../../api/index';
import { addDoc, collection } from 'firebase/firestore';
import { DB } from '../../contexts/FirebaseContext';
import { PremiumFeatureLabel } from '../../components/PremiumFeature';
import { useTranslation } from 'react-i18next';
import { widgetsConfig, WidgetState } from './config';
import { Stack } from '@mui/material';
import { NAVBAR } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '../../redux/store';
import {
  selectCurrentPlatformIntegrationsMap,
  selectCurrentPlatformIntegrationsMapType,
} from '../../redux/selectors/integrations';

const STEPS = [
  'integrations.steps.pick',
  'integrations.steps.copy',
  'integrations.steps.addedSite',
];

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  top: 10,
  left: 'calc(-50% + 20px)',
  right: 'calc(50% + 20px)',
  '& .MuiStepConnector-line': {
    borderTopWidth: 2,
    borderColor: theme.palette.divider,
  },
  '&.Mui-active, &.Mui-completed': {
    '& .MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export default function IntegrationsWizard({ handleClose }: { handleClose: () => void }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const userId = useSelector((state) => state.workspaceReview.currentWorkspace?.id);
  const navigate = useNavigate();
  const [widgetType, setWidgetType] = useState<WidgetType | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const [currentWidgetId, setCurrentWidgetId] = useState<string | null>(null);
  const integrationMap = useSelector(selectCurrentPlatformIntegrationsMap);
  const currentWidget = currentWidgetId && integrationMap[currentWidgetId];
  // @ts-ignore
  const isActive = currentWidget?.state === 'ACTIVE';
  // TODO: disable that you can pick multiple of the same if active
  const integrationTypeMap = useSelector(selectCurrentPlatformIntegrationsMapType);

  const selectWidget = async (type: WidgetType) => {
    setWidgetType(type);

    // @ts-ignore
    if (userId && !integrationTypeMap[type]) {
      const ref = await addDoc(
        collection(DB, getCollection[Collection.USERS_INTEGRATIONS](userId)),
        {
          createTime: new Date(),
          type,
          state: WidgetState.INACTIVE,
        }
      );
      setCurrentWidgetId(ref.id);
    }
    // @ts-ignore
    if (userId && integrationTypeMap[type]) {
      // @ts-ignore
      setCurrentWidgetId(integrationTypeMap[type]?.id as string);
    }

    setTimeout(() => {
      setActiveStep(1);
    }, 500);
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} sx={{ pb: 12 }}>
        {activeStep === 0 && (
          <Grid container spacing={3} sx={{ pr: 6 }}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h3">{t('widgets.setup.type.headline')}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <WidgetCard
                onSelect={() => selectWidget(WidgetType.MICRO_STARS)}
                widget={{
                  title: t(widgetsConfig.minireviewcount.title),
                  cover: widgetsConfig.minireviewcount.cover,
                }}
                selected={WidgetType.MICRO_STARS === widgetType}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4}>
              <WidgetCard
                onSelect={() => selectWidget(WidgetType.HEADER_BAR_STARS)}
                widget={{
                  title: t(widgetsConfig.headerbar.title),
                  cover: widgetsConfig.headerbar.cover,
                }}
                selected={WidgetType.HEADER_BAR_STARS === widgetType}
              />
            </Grid> */}

            <Grid item xs={12} sm={12} md={4}>
              <WidgetCard
                onSelect={() => selectWidget(WidgetType.REVIEW_CARDS)}
                widget={{
                  title: (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{t(widgetsConfig.textcarousel.title)}</Typography>
                      <PremiumFeatureLabel />
                    </Stack>
                  ),
                  cover: widgetsConfig.textcarousel.cover,
                }}
                selected={WidgetType.REVIEW_CARDS === widgetType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <WidgetCard
                onSelect={() => selectWidget(WidgetType.VIDEO_CARDS)}
                widget={{
                  title: (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{t(widgetsConfig.videocarousel.title)}</Typography>
                      <PremiumFeatureLabel />
                    </Stack>
                  ),
                  cover: widgetsConfig.videocarousel.cover,
                }}
                selected={WidgetType.VIDEO_CARDS === widgetType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <WidgetCard
                onSelect={() => selectWidget(WidgetType.TEXT_VIDEO_CARDS)}
                widget={{
                  title: (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>{t(widgetsConfig.textvideocarousel.title)}</Typography>
                      <PremiumFeatureLabel />
                    </Stack>
                  ),
                  cover: widgetsConfig.textvideocarousel.cover,
                }}
                selected={WidgetType.TEXT_VIDEO_CARDS === widgetType}
              />
            </Grid>
          </Grid>
        )}
        {activeStep === 1 && (
          <Grid container spacing={3}>
            <Step2 currentWidgetId={currentWidgetId} widgetType={widgetType} />
          </Grid>
        )}
      </Grid>
      <Stack
        sx={{
          position: 'fixed',
          left: 0,
          width: '100%',
          bottom: 0,
          backgroundColor: '#fff',
          p: 2,
          pr: 4,
          paddingLeft: `${NAVBAR.DASHBOARD_COLLAPSE_WIDTH + NAVBAR.PRODUCT_NAVBAR_WIDTH}px`,
          borderTop: `1px solid ${theme.palette.grey[200]}`,
        }}
        direction="row"
        alignItems="center"
        justifyItems="space-between"
      >
        <Box sx={{ width: '50%' }}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {STEPS.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={QontoStepIcon}
                  sx={{
                    '& .MuiStepLabel-label': {
                      typography: 'subtitle2',
                      color: 'text.disabled',
                      m: 0,
                    },
                  }}
                >
                  {t(label)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={1}
          sx={{ width: '50%', textAlign: 'right' }}
        >
          {activeStep > 0 && (
            <Button variant="text" size="large" onClick={() => setActiveStep(activeStep - 1)}>
              {t('general.back')}
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            disabled={(!widgetType && activeStep === 0) || (!isActive && activeStep === 1)}
            onClick={() =>
              activeStep > 0 ? navigate('/dashboard/integrations') : setActiveStep(activeStep + 1)
            }
          >
            {activeStep > 0 ? t('general.done') : t('general.next')}
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

function QontoStepIcon({ active, completed }: { active: boolean; completed: boolean }) {
  return (
    <Box
      sx={{
        zIndex: 9,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: active ? 'primary.main' : 'text.disabled',
      }}
    >
      {completed ? (
        <Iconify
          icon={'eva:checkmark-fill'}
          sx={{ zIndex: 1, width: 20, height: 20, color: 'primary.main' }}
        />
      ) : (
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
          }}
        />
      )}
    </Box>
  );
}
